.Extract-Manager{
    margin-top: 10px;
}
.single-btn{
    margin: 10px;
    background-color: #cacacc;
    border-color: #cacacc;
    color: black;
    max-height: fit-content;
}
.single-btn-export{
    /*margin: 50px 10px 10px;*/
    color: black;
    background-color: #cacacc;
    border-color: #cacacc;
}
.active-btn{
    margin: 10px;
    max-height: 40px;
}
.border{
    border: 1px solid black;
    border-radius: .25em;
    padding: .1em .3em;
    margin: .1em .3em .1em 0;
    line-height: 1.5em;
    font-size: 0.75em;
}
