.column{
    max-width: 15%;
    font-size: 10px;
}
.attr {
    background-color: #feffdb;
    font-size: 5px;
}
.active-value{
    background-color: #dbffdb;
}

table.Stats-qa tbody tr td {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
}

.qa-table {
    font-size: 12px;
}
.add-attr-link {
    color: blue;
    text-decoration-line: underline;
}

.MuiMenu-paper {
 width:200px
}
