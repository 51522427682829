.Navigation-Bar{
    position: fixed;
    margin-left: 10px;
    width: 24%;
    border: 1px solid #959595;
    border-radius: .5em;
    padding: .1em .3em;
    overflow-x: hidden;
}

.library{
    position: fixed;
    margin-top: 8%;
    margin-left: 10px;
    width: 24%;
    border: 1px solid #959595;
    border-radius: .5em;
    padding: .1em .3em;
    overflow-y: auto;
    max-height: 70%;
}

.Navigation-class{
    margin-left: 15px;
    overflow-x: hidden;
    font-size: 11px;
}

.Navigation-attr{
    margin-left: 15px;
    overflow-x: hidden;
    font-size: 10px;
}
.batches{
    width: 100%;
    align-self: center;
}
.dropdown-menu.show {
    display: contents;
    width: 100%
}
.dropdown-toggle {
    white-space: nowrap;
    width: 100%;
    margin: 5px 0;
    background-color: #cacacc;
}
