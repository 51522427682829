.token {
    border: 1px solid #959595;
    border-radius: .25em;
    padding: .1em .3em;
    margin: .1em .3em .1em 0;
    line-height: 1.5em;
    cursor: default;
    background-color: #ccffcc;
    display: inline-block;
}

.token-exact {
    background-color: #ffffaa;
}
table.Test tbody tr td {
    line-height: 2.2em;
    text-align: center;
    border-right-width:  2.2px;
    border-color: #cacacc;
}

table.Test thead tr th {
    line-height: 2.2em;
    text-align: center;
    background-color: #d9d9d9;
    border-right-width:  2.2px;
    border-color: #cacacc;
    vertical-align: middle;
}
