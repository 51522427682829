#logo {
	height: 48px;
}

header.ant-layout-header {
	background: linear-gradient(90deg, rgba(0,32,92,1) 0%, rgba(0,75,135,1) 100%);
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
	z-index: 999;
}
#home-button > svg {
	position: relative;
	top: calc(50% - 16px);
}

#user-icon > svg {
	margin-top: -10px;
}
