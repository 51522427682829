.delete-attr{
    margin: -1px 0 1px 5px;
    position: center;

}
.selected{
    padding: .25em .3em;
    margin: .1em .3em .1em 0;
    border: 1px solid black;
}

.selected-btn{
    border: 4px solid black;
    color: black;
    margin: 10px;
    max-height: fit-content;
}
