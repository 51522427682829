
.link {
    color: blue;
    text-decoration: underline;
}

.beautiful-link {
    color: #007fc5;
    text-decoration: underline;
}

.site-dropdown-context-menu {
    color: #777;
    background: #f7f7f7;
}

.first {
    color: green;
    background: #dbffdb33;
}

.second {
    color: orange;
    background: #ffeedb33;
}

.third {
    color: red;
    background: #ffdcdb33;
}


.empty {
    color: #777;
    width: auto;
    height: auto;
    background: #f7f7f7;
}
